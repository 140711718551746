import React from 'react';
import { BsTwitter, BsInstagram, BsLinkedin, BsMailbox2, BsPaperclip } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
    <div className="app__social">
        <div>
            
        <a href="https://drive.google.com/file/d/1kZ_oFF6dQTHg4f72_yq6xbX7u6zYzAdx/view?usp=sharing" target="_blank" rel="noopener noreferrer" >
            <BsPaperclip />
            </a>
        </div>
        <div>
        <a href="https://www.linkedin.com/in/abishanarul/" target="_blank" rel="noopener noreferrer">
            <BsLinkedin />
            </a>
        </div>
        <div>
        <a href="mailto:arul.abishanpro@gmail.com">
            <BsMailbox2 />
            </a>
        </div>
    </div>
);

export default SocialMedia;