import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from 'react-tooltip';


import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Education.scss';

const Certificates = () => {
  const [educationexperiences, setEducationExperiences] = useState([]);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const query = '*[_type == "educationexperiences"]';
    const skillsQuery = '*[_type == "certificates"]';

    client.fetch(query).then((data) => {
      setEducationExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      setCertificates(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">Certifications & Education</h2>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {certificates.map((certificate) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={certificate.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: certificate.bgColor }}
              >
                <a href={certificate.certificateLink} target="_blank" rel="noopener noreferrer">
                        <img src={urlFor(certificate.icon).url()} alt={certificate.name} />
                    </a>
              </div>
              <p className="p-text">{certificate.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <div className="app__skills-exp"> 
          {educationexperiences.map((educationexperience) => (
            <motion.div
              className="app__skills-exp-item"
              key={educationexperience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{educationexperience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {educationexperience.educationworks.map((educationwork) => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={educationwork.name}
                      key={educationwork.name}
                    >
                      <h4 className="bold-text">{educationwork.name}</h4>
                      <p className="p-text">{educationwork.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={educationwork.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {educationwork.desc}
                    </ReactTooltip>
                  </>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Certificates, 'app__skills'),
  'certificates',
  'app__whitebg',
);